<template lang="pug">
  v-layout(row)
    v-flex
      v-hover
        v-btn(
          slot-scope="{ hover }"
          @click="back"
          color="pink accent-1"
          small
          fab
          depressed
          :class="hover ? 'pink darken-4' : ''"
        ) 
          v-icon arrow_back

      v-hover
        v-btn(
          slot-scope="{ hover }"
          @click="openActions"
          color="pink accent-1"
          depressed
          fab
          small
        ) 
          v-icon more_vert
    
</template>

<script>
import { mapFields } from 'vuex-map-fields'

export default {
  computed: {
    ...mapFields('dashboards', {
      actions: 'actions',
    })
  },
  methods: {
    back () {
      this.$router.go(-1)
    },

    async openActions () {
      this.actions = true
    }

  }
}
</script>

<template lang="pug">
v-container
  v-layout(row wrap)
    v-flex(v-for="(comment, i) in items" :key="i").mb-4
      v-card.grey.lighten-2(flat)
        v-card-title.font-weight-bold.subheading.grey.white--text()
          | type:
          span.font-weight-light.pl-2  {{comment.type}}
          span.pl-4 subject:  
          span.font-weight-light.pl-2 {{comment.subject}}
        v-card-text
          v-layout(row style="{white-space: pre-line; white-space: pre-wrap;}")
            v-flex {{comment.text}}
        v-card-actions.grey.white--text.ma-0
          v-layout(row)
            v-flex(xs6)
              span.font-weight-bold submitted on: 
              | {{formatDate(comment.time)}}
</template>

<script>
import moment from 'moment'

export default {
  props: {
    items: {
      required: true,
      type: Array
    }
  },
  methods: {
    formatDate (date) {
      return moment(date).format('LLL')
    }
  }
}
</script>

<template lang="pug">
v-container
  v-layout(row v-if='comments.length > 0')
    v-flex
      h2.display-1.red--text rejected by screen department 
      CommentList(:items='comments')
      
  v-layout(row)
    v-flex
      .headline.font-weight-bold pre-rip checklist
      p.mt-1 please verify that these checks have been completed before releasing the rip file
      PreRipChecklist
      
  v-layout.mt-5(row)
    v-flex
      .headline.font-weight-bold choose a file 
      p.mt-1 these are all available rips for this design so there may be multiple different locations available.
      p.red--text please choose the most updated rip for this location
        FileListRips

  v-layout.mt-5(row)
    v-flex()
      .headline.font-weight-bold selected rip file
      p.mt-1 this is what the screen department will receive once 'sent to screens'
    v-flex(xs6 pl-3)
      RipCard

  v-layout.mt-5(row)
    //- v-flex
    //-   pre {{ ripFile }}
      
    //- v-flex
    //-   pre {{ checklist }}
    v-flex
      v-btn(
        color='green' 
        large 
        :disabled='!ripFile.url || !ischecklistcompleted'
        @click='sendtoscreens'
      ).white--text send to screens
</template>

<script>
import moment from 'moment'
import CommentList from '@/components/base/comment-list'
import { mapFields } from 'vuex-map-fields'
import FileListRips from './files'
import PreRipChecklist from './checklist'
import RipCard from './rip-card'

export default {
  components: { FileListRips, CommentList, PreRipChecklist, RipCard },
  computed: {
    ...mapFields('productionInstances', {
      item: 'item',
      isArtRipped: 'item.progress.isArtRipped',
      ripFile: 'item.ripFile',
      comments: 'item.comments',
      checklist: 'item.preripchecklist'
    }),
    ischecklistcompleted () {
      for (const key in this.checklist) {
        if(!this.checklist[key]) return false
      }
      return true
    }

  },
  methods: {
    async sendtoscreens () {
      this.isArtRipped = true
      await this.$store.dispatch('productionInstances/updatePiProgress')
      this.$router.push('/dashboards/art')
    },
    

  }
}
</script>

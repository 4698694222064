<template lang="pug">
div
  v-layout(row).font-weight-bold 1) separations
  v-layout(row v-for='(item, i) in separationitems' :key='i') 
    v-checkbox(:label='item.label' v-model='checklist[item.model]' color='green' @change='change')

  v-layout(row).font-weight-bold 2) rip
  v-layout(row v-for='(item, i) in ripitems' :key='i+100') 
    v-checkbox(:label='item.label' v-model='checklist[item.model]' color='green' @change='change')

  v-layout(row).font-weight-bold 3) relabel
  v-layout(row) If this is not a relabel rip then check all of these off so that you can release to screens
  v-layout(row v-for='(item, i) in relabelitems' :key='i+1000') 
    v-checkbox(:label='item.label' v-model='checklist[item.model]' color='green' @change='change')
</template>

<script>
import { mapFields } from 'vuex-map-fields'

export default {
  data: () =>({
    separationitems: [
      {
        label: 'have any external separation files been uploaded to the design record?',
        model: 'issepuploaded'
      },
      {
        label: 'are uploaded seps properly named? (Company - Design LOCATION - SEP.eps,  .zip)',
        model: 'issepnamed'
      },
      {
        label: 'does the sep file have the print colors properly named, corresponding to the print locations listed colors?',
        model: 'issepcolorsnamed'
      },
    ],
    ripitems: [
      {
        label: 'are colors listed on the rip template and assigned the proper colors?',
        model: 'isripcolors'
      },
      {
        label: 'do the colors in the rip match the colors listed on the mockup?',
        model: 'isripcolorsmatchmockup'
      },
      {
        label: 'is the print sized properly to match the mockup?',
        model: 'isripcorrectsize'
      },
      {
        label: 'are registration marks outside of the design print area?',
        model: 'isripregistrationmarks'
      },
    ],
    relabelitems: [
      {
        label: 'does blend and country of origin match the mockup?',
        model: 'isrelabeldetails'
      },
      {
        label: 'have you removed any unnecessary sizes based on this order\'s quantities?',
        model: 'isnecessarysizes'
      },
    ],
  }),
  computed: {
    ...mapFields('productionInstances', {
      checklist: 'item.preripchecklist'
    })
  },
  methods: {
    change () { this.$store.dispatch('productionInstances/_UPDATE') }
  }
}
</script>

<style lang="stylus" scoped>
.no-wrap
  white-space nowrap
  overflow hidden
  text-overflow ellipsis
</style>

<template lang="pug">
div
  FwdDetailPage(v-if="loaded")
    template(slot="title-prefix") Art Order: 
    template(slot="title") {{item.dashboard.designName}} - {{item.dashboard.jobSubtitle}} - 
      span.font-weight-bold {{item.location.template.name}}
    template(slot="controls")
      Controls
    template(slot="form")
      ArtOrder
  v-dialog(max-width=600 v-model="actions")
    FwdCard
      template(slot="title") actions
      template(slot="content")
        Actions
</template>

<script>
import { mapFields } from 'vuex-map-fields'
import DetailFull from '@/components/base/detail-full-page'
import ArtOrder from './art-order'
import Controls from './controls'
import Actions from './actions'

export default {
  components: {
    DetailFull,
    ArtOrder,
    Controls,
    Actions
  },
  data: () => ({ loaded: false }),
  computed: {
    item () { return this.$store.getters['productionInstances/item'] },
    ...mapFields('dashboards', {
      actions: 'actions'
    })
  },
  async created () {
    await this.$store.dispatch('productionInstances/_READ_FROM_DB', this.$route.params.id)
    // await this.$store.dispatch('designs/_LOAD', this.item.design._id)
 
    this.loaded = true
  }
}
</script>

<template lang="pug">
v-layout(row)
  v-flex(xs6)
    v-btn(
      @click="action"
      color="pink accent-1"
    ).white--text jump to design
</template>

<script>
import { mapFields } from 'vuex-map-fields'

export default {
  computed: {
    ...mapFields('dashboards', {
      actions: 'actions'
    }),
    item () { return this.$store.getters['productionInstances/item']}
  },
  methods: {
    async action() {
      this.$router.push({ path: '/designs/' + this.item.design})
      this.actions = false
    }
  }
}
</script>

<template lang="pug">
div
  JumpToJob
  JumpToDesign
</template>

<script>
import JumpToJob from './JumpToJob'
import JumpToDesign from './JumpToDesign'
export default {
  components: {
    JumpToJob,
    JumpToDesign,
  }
}
</script>

<template lang="pug">
div
  v-list(
    :items="itemsSorted"
  )
    template(v-for='(file, i) in itemsSorted')
      v-list-tile(
        :key='i' 
        @click='select(file)'
        :class="selectedclass(file)"
      ).list-tile-border
        v-layout.body-1(row)
          v-flex(xs4).no-wrap {{file.name}}
          v-flex(xs4).no-wrap {{file.notes}}
          v-flex(xs4).no-wrap {{formatDate(file.meta.dateCreated)}}
</template>

<script>
import moment from 'moment'
import { File, Upload } from '@/models'
import { mapFields } from 'vuex-map-fields'
import FwdList from '@/components/base/list-selected-single-line'

export default {
  components: { FwdList },
  computed: {
    ...mapFields('designs', {
      items: 'item.files.rips',
      files: 'item.files',
    }),
    ...mapFields('productionInstances', {
      item: 'item.ripFile',
    }),
    itemsSorted () {
      return this.sortByPropNumber(this.items, 'dateCreated').reverse()
    }
  },
  methods: {
    select (item) {
      if (this.item._id === item._id) {
        this.item = {}
        this.$store.dispatch('productionInstances/_UPDATE')
        return
      }
      this.item = new File(item)
      this.$store.dispatch('productionInstances/_UPDATE')
    },
    cancel () {
      this.detail = false
    },

    formatDate(date) {
      return moment(date).format('L')
    },

    selectedclass (file) {
      if (!this.item) return ''
      if (this.item._id === file._id) return 'green lighten-2'
    }
  }
}
</script>

<style lang="stylus" scoped>
.no-wrap
  white-space nowrap
  overflow hidden
  text-overflow ellipsis

.list-tile-border
  border-bottom 1px solid green
</style>
